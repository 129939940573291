import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuEyeOff, LuEye, LuMail } from "react-icons/lu";
import { buildersignup } from "assets";

const BuilderSignup = ({ form, step, setStep, otpInput, handleSignUp, verifyOtpLogin, loading }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center mt-5"
            style={{ minHeight: "70vh" }}
        >
            <Row style={{ maxWidth: 1000 }}>
                <Col lg={6} className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center">
                    <Image src={buildersignup} alt="Homeowner signup" width="80%" />
                    <Card style={{ boxShadow: "none", borderRadius: 0, border: "none", borderLeft: "1px solid #BDC1C6" }}>
                        <h4 className="text-gray-900 inter-600">Builder Registration</h4>
                        <div className="text-gray-500">
                            List your upcoming and built properties to get the best
                            interior inspiration showcasing their versatility.
                        </div>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Form ref={form} onSubmit={(e) => handleSignUp(e)}>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Company Name<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                required
                                className="py-2 body-text2"
                                type="text"
                                name="full_name"
                                placeholder="Enter your Company Name"
                                minLength={3}
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Phone Number<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    className="body-text2 inter-600"
                                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                >
                                    +91
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="text"
                                    name="mobile"
                                    required
                                    placeholder="9090909090"
                                    maxLength={10}
                                    pattern="[0-9]*"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Company Email<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                >
                                    <LuMail size={20} color="#1A1C1F" />
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type="email"
                                    name="email"
                                    required
                                    placeholder="example@gmail.com"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Password<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    className="cursor-pointer"
                                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    placeholder="**********"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                    pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                                    title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1 body-text2 inter-600">
                                Confirm Password<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    className="cursor-pointer"
                                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: "3px 0px 0px 3px" }}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                                </InputGroup.Text>
                                <Form.Control
                                    className="border-start-0 p-2 body-text2"
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirm_password"
                                    placeholder="**********"
                                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                                    pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                                    title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                                />
                            </InputGroup>
                        </Form.Group>
                        {otpInput && (
                            <Form.Group className="my-3">
                                <Form.Label className="mb-1 body-text2 inter-600">
                                    OTP<sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Row className="">
                                    <Col xs="6">
                                        <Form.Control
                                            required
                                            className="py-2 body-text2"
                                            type="text"
                                            name="email_verified_otp"
                                            placeholder="****"
                                            style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                        />
                                    </Col>
                                    <Col xs="3">
                                        <Button
                                            variant="success"
                                            className="w-100 text-white py-2"
                                            style={{ borderRadius: 3 }}
                                            onClick={() => verifyOtpLogin()}
                                        >
                                            Verify
                                        </Button>
                                    </Col>
                                    <Col xs="3">
                                        <Button
                                            className="text-white w-100 py-2 px-3"
                                            style={{ borderRadius: 3 }}
                                        >
                                            Resend
                                        </Button>
                                    </Col>
                                </Row>
                                <Form.Text className="text-success">
                                    OTP has been sent to the email address
                                </Form.Text>
                            </Form.Group>
                        )}
                        <div className="d-flex mt-5 w-100">
                            <Button
                                className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                                style={{ borderRadius: 3 }}
                                onClick={() => setStep(step - 1)}
                            >
                                Back
                            </Button>
                            <Button
                                className="w-100 p-2"
                                type="submit"
                                style={{ borderRadius: 3 }}
                                disabled={otpInput}
                            >
                                {loading && (
                                    <Spinner animation="border" size="sm" className="me-2" />
                                )}
                                Register
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default BuilderSignup