import { useEffect, useState } from "react";
import AllProfessionalCard from "components/Cards/AllProfessionalCard";
import ProjectCard from "components/Cards/ProjectCard";
import SpaceCard from "components/Cards/SpaceCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import SpacesSliderView from "pages/Projects/SpacesSliderView";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

export const Library = ({ showAllRecords = false, showInspiration = true, showSpace = true, showProfessional = true }) => {
  const navigate = useNavigate();

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [projects, setProjects] = useState([]);
  const [projectLength, setProjectLength] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [roomLength, setRoomLength] = useState(0);
  const [professionals, setProfessionals] = useState([]);
  const [professionalLength, setProfessionalLength] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [roomData, setRoomData] = useState(null);

  const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchSavedDataByUser(
        {
          user: user.id,
          inspirations: showInspiration,
          spaces: showSpace,
          professionals: showProfessional,
        }
      );
      if (response.data) {
        if (showInspiration) {
          console.log("Storing Inspirations");
          setProjectLength(response.data["saved_projects"].length);
          setProjects(showAllRecords ? response.data["saved_projects"] : response.data["saved_projects"].slice(0, itemsInRow));
        }
        if (showSpace) {
          setRoomLength(response.data["saved_rooms"].length)
          setRooms(showAllRecords ? response.data["saved_rooms"] : response.data["saved_rooms"].slice(0, itemsInRow));
        }
        if (showProfessional) {
          setProfessionalLength(response.data["saved_professionals"].length)
          setProfessionals(showAllRecords ? response.data["saved_professionals"] : response.data["saved_professionals"].slice(0, itemsInRow));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [showInspiration, showSpace, showProfessional]);

  return (
    <Container className="py-4 px-0">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          {showInspiration &&
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0">Saved Inspirations ({!loading && projectLength})</div>
                {!showAllRecords && <div
                  className="body-text2 text-gray-900 inter-500 cursor-pointer"
                  onClick={() => navigate(`/user/saved#inspirations`)}
                >
                  View all
                </div>}
              </div>
              <Row className={`${projects.length > 0 ? "" : "px-3"}`}>
                {projects?.length > 0 ? (
                  projects?.map((project, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={6}
                      className="d-flex justify-content-around my-3"
                    >
                      <ProjectCard
                        {...project}
                        id={project?.project_data?.id}
                        project_name={project?.project_data?.project_name}
                        image={project?.project_data?.rooms[0]?.media[0]?.file}
                        type={`${project?.project_data?.bhk}, ${project?.project_data?.project_area}`}
                        architect={project?.project_data?.company_name}
                        location={project?.project_data?.property_data?.location}
                        builders={
                          project?.project_data?.property_data?.builder_data
                            ?.company_name
                        }
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: '60vh' }}
                  >
                    No projects available
                  </div>
                )}
              </Row>
            </div>
          }
          {showSpace &&
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0">Saved Spaces ({!loading && roomLength})</div>
                {!showAllRecords && <div
                  className="body-text2 text-gray-900 inter-500 cursor-pointer"
                  onClick={() => navigate(`/user/saved#spaces`)}
                >
                  View all
                </div>}
              </div>
              <Row className={`${rooms.length > 0 ? "" : "px-3"}`}>
                {rooms?.length > 0 ? (
                  rooms?.map((room, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={6}
                      className="d-flex justify-content-around my-3"
                    >
                      <SpaceCard
                        {...room?.room_data}
                        id={room?.room_data?.id}
                        project={room?.room_data?.project}
                        image={room?.room_data?.media[0]?.file}
                        title={`${room?.room_data?.room_type_data?.title}/${room?.room_data?.project_data?.property_name}`}
                        type={`${room?.project_data?.bhk}, ${room?.project_data?.project_area}`}
                        setShow={setShowSpaceView}
                        setRoomData={setRoomData}
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: '60vh' }}
                  >
                    No spaces available
                  </div>
                )}
              </Row>
              <SpacesSliderView
                ativeRoomIndex={activeSpaceIndex}
                setActiveRoomIndex={setActiveSpaceIndex}
                show={showSpaceView}
                setShow={setShowSpaceView}
                roomData={roomData}
              />
            </div>
          }
          {showProfessional &&
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h4 inter-600 mb-0">Saved Professionals ({!loading && professionalLength})</div>
                {!showAllRecords && <div
                  className="body-text2 text-gray-900 inter-500 cursor-pointer"
                  onClick={() => navigate(`/user/saved#professionals`)}
                >
                  View all
                </div>}
              </div>
              <Row className={`${professionals.length > 0 ? "" : "px-3"}`}>
                {professionals?.length > 0 ? (
                  professionals?.map((professional, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={6}
                      className="d-flex justify-content-around my-3"
                    >
                      <AllProfessionalCard
                        key={index}
                        {...professional}
                        name={professional.professional_data?.full_name}
                        // slug,
                        company_name={
                          professional?.architect_data?.company_name ??
                          professional?.builder_data?.company_name ??
                          professional?.brand_data?.company_name ??
                          ""
                        }
                        location={
                          professional?.architect_data?.location ??
                          professional?.builder_data?.location ??
                          ""
                        }
                        rating={
                          professional?.architect_data?.rating ??
                          professional?.builder_data?.rating ??
                          ""
                        }
                        project_count={
                          professional?.architect_data?.project_count ??
                          professional?.builder_data?.project_count ??
                          ""
                        }
                        expertise={
                          professional?.architect_data?.expertise ??
                          professional?.builder_data?.expertise ??
                          "-"
                        }
                        project_names={
                          professional?.architect_data?.project_names ??
                          professional?.builder_data?.project_names ??
                          ""
                        }
                        type={professional.professional_data?.user_type}
                        image={
                          professional.professional_data?.profile_picture
                        }
                      />
                    </Col>
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                    style={{ height: '60vh' }}
                  >
                    No professional available
                  </div>
                )}
              </Row>
            </div>
          }
        </div>
      )
      }
    </Container >
  );
};

export default Library;
