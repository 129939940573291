import { Accordion, Button, Container, Spinner, Table } from "react-bootstrap";
import { LuPhoneCall } from "react-icons/lu";
import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import { useSelector } from "react-redux";
import ProjectReferenceCard from "components/Order/ProjectReferenceCard";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import ArchitectReferenceCard from "components/Order/ArchitectReferenceCard";
import SpaceReferenceCard from "components/Order/SpaceReferenceCard";

const ProjectTracker = () => {
  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const stage1 = "Stage 1: Consultation";
  const stage2 = "Stage 2: Requirement Gathering and Site Measurement";
  const stage3 = "Stage 3: Creating Customized Drawings and 3D Package";
  const stage4 = "Stage 4: Site Execution";

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchUserOrders(user.id);
      if (response.data) {
        setOrders(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onClickPrevious = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const onClickNext = () => {
    if (activeIndex < orders.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      fetchOrders();
    }
  }, [user]);

  return (
    <Container className="mt-5 pt-4 px-0">
      <div className="h2 inter-600 mb-4">Project Tracker</div>
      <div className="d-flex flex-column bg-gray-25 border-bottom rounded mb-4">
        <div className="p-3 pb-4">
          {!loading ? (
            orders?.length > 0 ? (
              <div>
                <div className="mb-4">
                  <div className="d-flex mb-3">
                    <div>
                      <div>
                        <span className="inter-600 text-capitalize">
                          Project(Service) Title:{" "}
                        </span>
                        {orders[activeIndex]?.bhk ?? ""}{" "}
                        {orders[activeIndex]?.service_type ?? ""}
                        {orders[activeIndex]?.project ?? ""}
                        {/* 3BHK Complete Home Interiors @ Jewel of India */}
                      </div>
                      <div>
                        <span className="inter-600">Client Name: </span>
                        {user?.full_name}
                      </div>
                    </div>
                    <div className="ms-auto">
                      {/* <div className="bg-gray me-3"><FaXmark size={20} /> Cancel Consultation</div> */}
                      <Button className="text-white">
                        <LuPhoneCall /> Contact Us
                      </Button>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="inter-600">Details:</div>
                    <div className="text-capitalize">
                      <span className="inter-600">Project Type:</span>{" "}
                      {/* {orders[activeIndex].project_type_data?.title ?? "-"} */}
                      {orders[activeIndex].project_type ?? "-"}
                    </div>
                    <div>
                      <span className="inter-600">Your Floor Plan:</span>{" "}
                      {orders[activeIndex]?.floorplan ?? "-"}
                    </div>
                    <div className="text-capitalize">
                      <span className="inter-600">Your Requirement:</span>{" "}
                      {orders[activeIndex]?.requirement
                        ?.split("_")
                        ?.join(" ") ?? "-"}
                    </div>
                  </div>
                  <div>
                    <div className="inter-600 mb-2">Status</div>
                    <section className="w-100 mb-3">
                      {orders[activeIndex]?.current_step <= 4 ? (
                        <InvoiceAccordion
                          title={stage1}
                          order={orders[activeIndex]}
                          currentIndex={1}
                        />
                      ) : (
                        <ProjectCostSummary
                          title={stage1}
                          currentIndex={1}
                          order={orders[activeIndex]}
                        />
                      )}
                    </section>
                    <section className="w-100 mb-3">
                      {orders[activeIndex]?.current_step >= 2 ? (
                        <InvoiceAccordion
                          title={stage2}
                          order={orders[activeIndex]}
                          currentIndex={2}
                        />
                      ) : (
                        <ProjectCostSummary
                          title={stage2}
                          currentIndex={2}
                          order={orders[activeIndex]}
                        />
                      )}
                    </section>
                    <section className="w-100 mb-3">
                      {orders[activeIndex]?.current_step >= 3 ? (
                        <InvoiceAccordion
                          title={stage3}
                          order={orders[activeIndex]}
                          currentIndex={3}
                        />
                      ) : (
                        <ProjectCostSummary
                          title={stage3}
                          currentIndex={3}
                          order={orders[activeIndex]}
                        />
                      )}
                    </section>
                    <section className="w-100 mb-3">
                      {orders[activeIndex]?.current_step === 4 ? (
                        <InvoiceAccordion
                          title={stage4}
                          order={orders[activeIndex]}
                          currentIndex={4}
                        />
                      ) : (
                        <ProjectCostSummary
                          title={stage4}
                          currentIndex={4}
                          order={orders[activeIndex]}
                        />
                      )}
                    </section>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className={`d-flex justify-content-center align-items-center bg-info text-gray-900 border border-secondary rounded-circle p-2 ${activeIndex > 0 && "cursor-pointer"
                      }`}
                    style={{ width: 40, height: 40 }}
                    onClick={onClickPrevious}
                  >
                    <BiChevronLeft size={24} />
                  </div>
                  <div className="fw-bold text-center mx-2">
                    Project ({activeIndex + 1}/{orders.length})
                  </div>
                  <div
                    className={`d-flex justify-content-center align-items-center bg-info text-gray-900 border border-secondary rounded-circle p-2 ${activeIndex < orders.length - 1 && "cursor-pointer"
                      }`}
                    style={{ width: 40, height: 40 }}
                    onClick={onClickNext}
                  >
                    <BiChevronRight size={24} />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                No projects available to track
              </div>
            )
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const ProjectCostSummary = ({ title, currentIndex, order }) => {
  const invoiceData = order?.invoices?.find(
    (item) => item?.step === currentIndex
  );

  return (
    <div className="d-flex justify-content-between align-items-center w-100 p-3 border border-extradark rounded">
      <div className="inter-600">{title}</div>
      {invoiceData?.percentage_pay && (
        <div className="border border-extragray bg-gray p-2">
          ({invoiceData?.percentage_pay}% Project Cost)
        </div>
      )}
    </div>
  );
};

const InvoiceAccordion = ({ title, order, currentIndex }) => {
  const invoiceData = order?.invoices?.find(
    (item) => item?.step === currentIndex
  );

  return (
    <Accordion
      defaultActiveKey={`${currentIndex}`}
      className="w-100 border border-extradark bg-gray-25 rounded"
    >
      <Accordion.Item className="rounded" eventKey={`${currentIndex}`}>
        <Accordion.Header>
          <div className="h4 inter-700 text-primary">
            <div
              className={`mb-2 ${order.current_step === currentIndex && "text-primary"
                }`}
            >
              {title}
            </div>
            <div className="body-text2 inter-500 text-gray-900"></div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {currentIndex === 1 && (
            <div>
              {order?.order_inspiration?.room && (
                <div>
                  <div className="inter-600 mb-2">
                    Selected Inspiration(Room)
                  </div>
                  <SpaceReferenceCard
                    room={order?.order_inspiration?.room_data}
                    className="bg-white"
                  />
                </div>
              )}
              {order?.order_inspiration?.project && (
                <div>
                  <div className="inter-600 mb-2">
                    Selected Inspiration(Project)
                  </div>
                  <ProjectReferenceCard
                    project={order?.order_inspiration?.project_data}
                    className="bg-white"
                  />
                </div>
              )}
              {order?.order_inspiration?.architect && (
                <div>
                  <div className="inter-600 mb-2">
                    Selected Inspiration(Architect)
                  </div>
                  <ArchitectReferenceCard
                    architect={order?.order_inspiration?.architect_data}
                    className="bg-white"
                  />
                </div>
              )}
            </div>
          )}
          {invoiceData ? (
            <div>
              <Table bordered responsive className="table-fixed">
                <thead
                  className="bg-gray-25 top-0"
                  style={{ position: "sticky", zIndex: 1000 }}
                >
                  <tr>
                    {/* {headers.map((header, index) => (<th key={index}>{header}</th>))} */}
                    <th>Service</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.invoice_services?.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <p className="mb-2 inter-500">{row?.service}</p>
                        <p
                          dangerouslySetInnerHTML={{ __html: row?.description }}
                        />
                      </td>
                      <td>{row?.amount}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    <td>{invoiceData?.total}</td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td>{invoiceData?.tax}</td>
                  </tr>
                  <tr>
                    <td className="inter-600">Total Payable</td>
                    <td>{invoiceData?.total_payable}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            currentIndex !== 1 && (
              <div className="inter-600 mb-2">
                Service information not provided
              </div>
            )
          )}
          <div className="d-flex justify-content-between align-items-center">
            {invoiceData && (
              <div className="fw-bold p-2">
                ({invoiceData?.percentage_pay}% Project Cost)
              </div>
            )}
            {order.current_step === currentIndex && (
              <div className="text-end ms-auto">
                <Button className="text-white me-3">Contact Us</Button>
                {currentIndex === 4 ? (
                  <Button variant="info" className="text-gray-900">
                    Final Payment
                  </Button>
                ) : (
                  <Button variant="info" className="text-gray-900" disabled>
                    Processing
                  </Button>
                )}
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProjectTracker;
