import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function PropertyCard({
  id,
  slug,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  builder,
  recommended,
  new_property,
  status,
  archive_request,
  setArchiveModal,
  setThisPropertyId,
  setThisPropertyName,
}) {

  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [display, setDisplay] = useState(false);
  const [inspirations, setInspirations] = useState([]);
  const [menu, setMenu] = useState(false);
  const [hover, setHover] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchProjectByProperty(id);
      if (response?.data?.results) {
        setInspirations(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProjects();
    }
  }, [id]);

  return (
    <Card
      className="p-0 d-flex flex-column text-start my-4 shadow-none border-gray-100"
      style={{ minHeight: 200, borderRadius: 6 }}
    >
      <Row className={`flex-wrap mx-0 ${display ? "border-bottom" : ""}`}>
        <Col md={4} lg={3} className="mb-2 mb-md-0 px-0" style={{ flexGrow: 1 }}>
          <Card.Img
            className="bg-gray-25 border-0"
            variant="top"
            src={property_img}
            style={{ height: 200 }}
          />
        </Col>
        <Col md={8} lg={9} className="p-0">
          <Card.Body className="w-100 h-100 p-2 p-md-3 d-flex flex-column justify-content-between">
            <div className="mb-2">
              <div className="d-flex flex-row justify-content-between">
                <Card.Title className="h4 inter-700">
                  {property_name} / {builder}
                </Card.Title>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="inter-500 pe-2 text-end body-text2">
                    {archive_request ?
                      <span className="inter-600 p-2" style={{ color: "#FC7C05", backgroundColor: "#F1F3F4" }}>ARCHIVE REQUESTED</span>
                      :
                      status === "UNDER_APPROVAL" ? (
                        <span style={{ color: "#FC7C05" }}>Pending Approval</span>
                      ) : status === "APPROVED" ? (
                        <span style={{ color: "#68C858" }}>Approved</span>
                      ) : status === "REJECTED" ? (
                        <span style={{ color: "#ff0000" }}>Rejected</span>
                      ) : (
                        <span style={{ color: "#FC7C05" }}>Designing</span>
                      )}
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center position-relative cursor-pointer"
                    style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: "#F1F3F4" }}
                    onClick={() => setMenu(!menu)}
                  >
                    <BsThreeDots size={18} />
                    <Card
                      className={`position-absolute shadow-none body-text2 p-0 ${menu ? "" : "d-none"}`}
                      style={{ right: 0, top: 30, borderRadius: 3, border: "1px solid #DADCE0", minWidth: 140 }}
                      onMouseLeave={() => setMenu(false)}
                    >
                      <div
                        className={`p-2 border-bottom ${hover === "edit" ? "bg-gray-50" : ""}`}
                        onMouseEnter={() => setHover("edit")}
                        onMouseLeave={() => setHover("")}
                        onClick={() => navigate(`${id}`)}
                      >
                        <span className={`${hover === "edit" ? "inter-500" : "inter-400"}`}>Edit Property</span>
                      </div>
                      <div
                        className={`p-2 border-bottom ${hover === "archive" ? "bg-gray-50" : ""}`}
                        onMouseEnter={() => setHover("archive")}
                        onMouseLeave={() => setHover("")}
                        onClick={() => {
                          setThisPropertyId(id)
                          setThisPropertyName(property_name)
                          setArchiveModal(true)
                        }}
                      >
                        <span className={`${hover === "archive" ? "inter-500" : "inter-400"}`}>Archive Property</span>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
              {/* <Stack direction={!xsmall && "horizontal"} gap={2}>
                {recommended && (
                  <Badge
                    bg="info text-primary"
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 30 }}
                  >
                    <CgScreenMirror size={20} className="me-1" />
                    <span className="body-text2 mt-1">
                      {" "}
                      Interiokeys Recommended
                    </span>
                  </Badge>
                )}
                {new_property && (
                  <Badge
                    bg="info text-primary"
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 30 }}
                  >
                    <BsBuildingAdd size={20} className="me-1" />
                    <span className="body-text2 mt-1"> New Property!</span>
                  </Badge>
                )}
              </Stack> */}
            </div>

            <div className="body-text2 mb-2">
              <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                <span className="">Number of Flats: {flats}</span>
              </Card.Text>
              <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                <span className="">Layout Types: {layout_types ?? "-"}</span>
              </Card.Text>
              <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                <span className="">Project Type: {project_type}</span>
              </Card.Text>
              <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                <span className="">Location: {location}</span>
              </Card.Text>
            </div>
            {inspirations?.length > 0 ?
              !display && (<div
                className="text-primary-500 inter-500 body-text2 text-end"
                style={{ cursor: "pointer" }}
                onClick={() => setDisplay(true)}
              >
                See Inspirations Created ({inspirations?.length}){" "}
                <IoIosArrowDown size={16} color="black" />
              </div>)
              :
              <div className="inter-500 body-text2 text-end" style={{ color: "#2E3134" }}>
                No Inspirations
              </div>
            }
          </Card.Body>
        </Col>
      </Row>
      <div className={`px-3 py-2 ${display ? "d-block" : "d-none"}`}>
        <div className="d-flex justify-content-between">
          <div className="text-gray-900 inter-400 mt-2 mb-1">Inspirations Made By:</div>
          <div className="cursor-pointer" onClick={() => setDisplay(false)}>
            <IoIosArrowUp size={16} color="black" />
          </div>
        </div>
        <Row className="row-cols-3">
          {inspirations?.map((item, index) => (
            <Col key={index} className="p-2">
              <ProjectCard
                {...item}
                id={item.id}
                project_name={item.project_name}
                image={item.rooms[0]?.media[0]?.file}
                type={`${item.bhk}, ${item.project_area}`}
                architect={item?.company_name}
                boxShadow="none"
                imgHeight="150px"
              />
            </Col>
          ))}
        </Row>
        <div
          className="text-primary-500 inter-500 body-text2 text-end px-2 cursor-pointer"
          onClick={() => navigate(`/builder/inspirations`)}
        >
          View All ({inspirations.length})
        </div>
      </div>
    </Card>
  );
}

export default PropertyCard;
