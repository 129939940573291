import React, { useState } from "react";
import { Button, Card, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UserTypeSelection = ({ joinAs, isHomeOwner }) => {

    const navigate = useNavigate();
    const [currentCard, setCurrentCard] = useState(isHomeOwner ? joinAs[0] : joinAs[1]);

    return (
        <Container className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "70vh" }}
        >
            <div style={{ maxWidth: 500 }}>
                <div className="mt-5 inter-500">
                    Join As
                </div>
                <div className="mb-4">
                    {joinAs.map((opt) => {
                        return (
                            <Card
                                className={`cursor-pointer p-4 mt-3 ${opt.title === currentCard.title ? "border-black" : "border-gray-200"}`}
                                style={{ boxShadow: "none", borderRadius: 3 }}
                                onClick={() => {
                                    setCurrentCard(opt)
                                }}
                            >
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ backgroundColor: "#F8F9FA", width: 48, height: 48 }}
                                >
                                    <Image src={opt.icon} style={{ width: 32 }} />
                                </div>
                                <h4 className={`text-gray-900 ${opt.title === currentCard.title ? "inter-600" : ""}`}>
                                    {opt.title}
                                </h4>
                                <div className={`${opt.title === currentCard.title ? "text-gray-900" : "text-gray-500"}`}>
                                    {opt.description}
                                </div>
                            </Card>
                        )
                    })}
                </div>
                <Button
                    className="mt-5 p-2 w-100"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                        navigate(`/signup#${currentCard.title.toLowerCase()}`)
                    }}
                >
                    Join as {currentCard.title}
                </Button>
            </div>
        </Container>
    )
}

export default UserTypeSelection