import React, { useEffect, useState } from "react";
import { logout } from "reduxFiles/reducer/authSlices";
import {
  Button,
  Card,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";
import LoginModal from "components/Auth/LoginModal";
import * as authActions from "reduxFiles/actions/authActions";
import { interiokeys_full_logo } from "assets";
import { notify } from "utilities/notifications/Notify";

import InspirationMegaMenu from "./InspirationMegaManu";
import BrandMegaMenu from "./BrandMegaMenu";
import ProfessionalMegaMenu from "./ProfessionalMegaMenu";
import LocationModal from "./LocationModal";
import { IoCloseCircleOutline } from "react-icons/io5";

const NavLink = ({ className, title, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const [hover, setHover] = useState(null);

  return (
    <Nav.Link
      className={`py-2 ${param1 === route ? "border-primary" : ""
        } ${className}`}
      onClick={() => {
        navigate(`/${route}`);
      }}
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      style={{
        color:
          hover || hover == ""
            ? hover === route
              ? "red"
              : "black"
            : param1 === route
              ? "red"
              : "black",
      }}
    >
      {title}
    </Nav.Link>
  );
};

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  const params = location.pathname.split("/").filter((item) => item !== "");
  // const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [locationDropdown, setLocationDropdown] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("Jaipur");
  const [hoveredLocation, setHoveredLocation] = useState("");

  const [showLocationModal, setShowLocationModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const searchResults = [
    { id: 1, title: "Properties", slug: "properties" },
    { id: 2, title: "Spaces", slug: "spaces" },
    { id: 3, title: "Projects", slug: "projects" },
  ]
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [hoveredSearchResult, setHoveredSearchResult] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("")

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [accHover, setAccHover] = useState(false);

  let inspirationHideTimeout;
  const [showInspirations, setShowInspirations] = useState(false);
  let brandHideTimeout;
  const [showBrands, setShowBrands] = useState(false);
  let professionalHideTimeout;
  const [showProfessionals, setShowProfessionals] = useState(false);

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = async () => {
    try {
      const response = await authActions.logout({ user: user });
      if (response) {
        dispatch(logout());
        notify("Logged out successfully!", "success")
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  return (
    <>
      <Navbar expand="lg" fixed="top" className="bg-white">
        <Container fluid className="px-4 pe-lg-1 pe-xxl-4 py-2">
          <Navbar.Brand href="/">
            <Image
              src={interiokeys_full_logo}
              alt="Interiokeys logo"
              style={{ maxWidth: !isLargeScreen ? 110 : 100 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="focus-none p-1 border-0"
          >
            {/* <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" /> */}
          </Navbar.Toggle>
          <Navbar.Collapse
            id="navbarScroll"
            className={
              large ? "d-flex justify-content-end" : "bg-extradark pb-4"
            }
          >
            <Nav.Item
              className="cursor-pointer text-white px-3 px-lg-2 body-text2"
              onClick={() => {
                setLocationDropdown(true);
              }}
              onMouseLeave={() => {
                setLocationDropdown(false);
              }}
            >
              <Nav.Link className="body-text2 inter-500 me-3">
                {currentLocation}
              </Nav.Link>
              <Card className={`${locationDropdown ? "position-absolute location-dropdown-container d-flex p-0 shadow-none" : "d-none"}`}>
                <Card.Body
                  className="p-0"
                  style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
                >
                  <div className="d-flex flex-column">
                    <div
                      className={`body-text2 text-gray-900 inter-600 p-2 w-100 ${hoveredLocation === "locate-me" ? "bg-gray-25" : ""}`}
                      onMouseEnter={() => setHoveredLocation("locate-me")}
                      onMouseLeave={() => setHoveredLocation("")}
                      onClick={() => {
                        setShowLocationModal(true)
                        setLocationDropdown(false)
                      }}
                    >
                      Locate Me
                    </div>
                    {allLocations
                      ?.filter((item) => item.is_visible)
                      ?.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className={`body-text2 text-gray-900 p-2 w-100 border-top ${hoveredLocation === item.slug ? "bg-gray-25" : ""}`}
                            onMouseEnter={() => setHoveredLocation(item.slug)}
                            onMouseLeave={() => setHoveredLocation("")}
                            onClick={() => {
                              setCurrentLocation(item?.title)
                              setLocationDropdown(false)
                            }}
                          >
                            {item?.title}
                          </div>
                        );
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Nav.Item>
            <Nav
              className={`body-text2 ${large
                ? "d-flex justify-content-end align-items-center geist"
                : "d-flex flex-column my-2 my-lg-0 geist"
                }`}
              navbarScroll
              style={{ fontWeight: 600, width: "100%" }}
            >
              {(!user || user.user_type === "customer") && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                >
                  <Nav.Item className="d-flex flex-grow">
                    <Form.Group className="d-flex w-100 position-relative">
                      <InputGroup style={{ height: 40 }}>
                        <InputGroup.Text
                          className="pe-2 px-lg-1 px-xxl-2"
                          style={{
                            borderLeft: "1px solid #BDC1C6",
                            borderTop: "1px solid #BDC1C6",
                            borderRight: 0,
                            borderBottom: "1px solid #BDC1C6",
                            borderRadius: "3px 0px 0px 3px",
                            backgroundColor: "white",
                          }}
                        >
                          <CiSearch size={20} color="#2E3134" style={{ strokeWidth: 1 }} />
                        </InputGroup.Text>
                        <Form.Control
                          size="sm"
                          placeholder="Search by project name, architect, builder and brand."
                          className="py-2 ps-1"
                          style={{
                            width: isLargeScreen && 120,
                            borderLeft: 0,
                            borderTop: "1px solid #BDC1C6",
                            borderRight: searchText ? 0 : "1px solid #BDC1C6",
                            borderBottom: "1px solid #BDC1C6",
                            borderRadius: searchText ? "0px 0px 0px 0px" : "0px 3px 3px 0px",
                          }}
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            if (e.target.value !== "") {
                              setSearchDropdown(true);
                            } else {
                              setSearchDropdown(false);
                            }
                          }}
                        />
                        {searchText &&
                          <div
                            className="d-flex justify-content-center align-items-center pe-2"
                            style={{
                              borderLeft: 0,
                              borderTop: "1px solid #BDC1C6",
                              borderRight: "1px solid #BDC1C6",
                              borderBottom: "1px solid #BDC1C6",
                              borderRadius: "0px 3px 3px 0px",
                            }}
                          >
                            {selectedCategory && <span className="me-2 inter-400 text-primary-500">in {selectedCategory}</span>}
                            <div
                              onClick={() => {
                                setSearchText("")
                                setSearchDropdown(false);
                              }}
                            ><IoCloseCircleOutline size={20} /></div>
                          </div>
                        }
                      </InputGroup>
                      <Card
                        style={{ top: 40 }}
                        className={`${searchDropdown ? "position-absolute search-dropdown-container d-flex p-0 shadow-none" : "d-none"}`}
                      >
                        <Card.Body className="p-0" style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}>
                          <div className="d-flex flex-column">
                            {searchResults?.map((item, index) => {
                              return (
                                <div
                                  key={item.id}
                                  className={`body-text2 inter-400 p-2 w-100 cursor-pointer 
                                    ${hoveredSearchResult === item?.slug ? "bg-gray-25" : ""}
                                    ${index === 0 ? "" : "border-top"} 
                                    d-flex justify-content-between`}
                                  onMouseEnter={() => setHoveredSearchResult(item.slug)}
                                  onMouseLeave={() => setHoveredSearchResult("")}
                                  onClick={() => {
                                    setSearchDropdown(false);
                                    setSelectedCategory(hoveredSearchResult);
                                    if (hoveredSearchResult === "projects") {
                                      navigate(`/projects?property=${searchText}`)
                                    } else if (hoveredSearchResult === "spaces") {
                                      navigate(`/spaces?property=${searchText}`)
                                    } else if (hoveredSearchResult === "properties") {
                                      navigate(`/professionals/builders?name=${searchText}`)
                                    }
                                  }}
                                >
                                  <span className="text-gray-900">{searchText}</span>
                                  <span className="text-primary-500">{item?.title}</span>
                                </div>
                              );
                            })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Form.Group>
                  </Nav.Item>

                  {(showInspirations || showBrands || showProfessionals) && (
                    <div className="huge-dropdown-overlay" />
                  )}
                  <Nav.Item
                    className="cursor-pointer text-white px-3 px-lg-2 body-text2"
                    onMouseEnter={() => {
                      clearTimeout(inspirationHideTimeout);
                      setShowInspirations(true);
                      setShowBrands(false);
                      setShowProfessionals(false);
                    }}
                    onMouseLeave={() => {
                      inspirationHideTimeout = setTimeout(() => {
                        setShowInspirations(false);
                      }, 500);
                    }}
                  >
                    <Nav.Link className="body-text2 inter-500">
                      Inspirations
                    </Nav.Link>
                    <InspirationMegaMenu
                      showInspirations={showInspirations}
                      setShowInspirations={setShowInspirations}
                    />
                  </Nav.Item>

                  <Nav.Item
                    className="cursor-pointer text-white px-3 px-lg-2 body-text2"
                    onMouseEnter={() => {
                      clearTimeout(brandHideTimeout);
                      setShowBrands(true);
                      setShowInspirations(false);
                      setShowProfessionals(false);
                    }}
                    onMouseLeave={() => {
                      brandHideTimeout = setTimeout(() => {
                        setShowBrands(false);
                      }, 500);
                    }}
                  >
                    <Nav.Link className="body-text2 inter-500">
                      Brands
                    </Nav.Link>
                    <BrandMegaMenu
                      showBrands={showBrands}
                      setShowBrands={setShowBrands}
                    />
                  </Nav.Item>

                  <Nav.Item
                    className="cursor-pointer text-white px-3 px-lg-2 body-text2"
                    onMouseEnter={() => {
                      clearTimeout(professionalHideTimeout);
                      setShowProfessionals(true);
                      setShowInspirations(false);
                      setShowBrands(false);
                    }}
                    onMouseLeave={() => {
                      professionalHideTimeout = setTimeout(() => {
                        setShowProfessionals(false);
                      }, 500);
                    }}
                  >
                    <Nav.Link className="body-text2 inter-500">
                      Professionals
                    </Nav.Link>
                    <ProfessionalMegaMenu
                      showProfessionals={showProfessionals}
                      setShowProfessionals={setShowProfessionals}
                    />
                  </Nav.Item>

                  <NavLink
                    title="Why Choose Us?"
                    route="why-choose-us"
                    className="ms-2 me-2 mx-lg-0 ms-xxl-2 me-xxl-2"
                  />
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 me-2 px-3 py-2 body-text2 inter-500 border-0 transparent-button"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/for-professionals")}
                    >
                      For Professionals
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/consultation")}
                    >
                      Book Consultation
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "builder" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-gray-900">
                    <Nav.Link href="/builder/inspirations">
                      Inspirations Created
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="text-gray-900">
                    <Nav.Link href="/builder/properties">
                      My Properties
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 me-2 px-3 py-2 body-text2 inter-500 border-0 transparent-button"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/for-professionals")}
                    >
                      For Professionals
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 px-3 py-2 body-text2 text-white inter-500 border-0"
                      style={{
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/builder/addproperty")}
                    >
                      Add New Property
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "architect" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-gray-900">
                    <Nav.Link href="/architect/product-library">
                      <span className={`${param2 === 'product-library' ? "text-primary" : "text-gray-900"}`}>Product Library</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/architect/projects">
                      <span className={`${param2 === 'projects' ? "text-primary" : "text-gray-900"}`}>Property Listing</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2"
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        border: 0,
                        fontSize: 14,
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/architect/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "brand" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-gray-900">
                    <Nav.Link href="/brand/product-library">
                      Product Library
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2 text-white border-0 rounded"
                      style={{ fontWeight: "600", fontSize: 14 }}
                      onClick={() => navigate("/brand/addproduct")}
                    >
                      Add Product
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2 text-white border-0 rounded"
                      style={{ fontWeight: "600", fontSize: 14 }}
                      onClick={() => navigate("/brand/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              <Nav.Item className="d-flex">
                {!isAuthenticated ? (
                  <NavDropdown
                    title="Login As"
                    className="d-flex flex-column justify-content-center ms-lg-2 me-xxl-0 mt-3 mt-lg-0 px-2 text-gray-900 bg-gray-50"
                    align={{ lg: "end" }}
                    style={{
                      minWidth: !large && 160,
                      minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(true);
                        setShowLoginModal(true);
                      }}
                      className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "homeowner" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("homeowner")}
                      onMouseLeave={() => setAccHover(null)}
                      style={{
                        minWidth: !large && 160,
                        minHeight: !medium && 32,
                        borderRadius: 3,
                      }}
                    >
                      Homeowner
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(false);
                        setShowLoginModal(true);
                      }}
                      className={`border-top body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "professional" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("professional")}
                      onMouseLeave={() => setAccHover(null)}
                      style={{
                        minWidth: !large && 160,
                        minHeight: !medium && 32,
                        borderRadius: 3,
                      }}
                    >
                      Professional
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    title={user?.full_name}
                    className="d-flex flex-column justify-content-center ms-lg-2 me-xxl-0 mt-3 mt-lg-0 px-2 text-gray-900 bg-gray-50"
                    align={{ lg: "end" }}
                    style={{
                      minWidth: !large && 160,
                      minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          `${user.user_type === "customer"
                            ? "/user/profile"
                            : user.user_type === "builder"
                              ? "/builder/account/profile"
                              : "/architect/account/profile"
                          }`
                        )
                      }
                      className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "profile" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("profile")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      View Profile
                    </NavDropdown.Item>
                    {user.user_type === "customer" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/user/projecttracker")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "tracker" ? "text-primary" : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("tracker")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Project Tracker
                      </NavDropdown.Item>
                    )}
                    {user.user_type === "builder" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/builder/properties")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "properties"
                          ? "text-primary"
                          : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("properties")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        My Properties
                      </NavDropdown.Item>
                    )}
                    {(user.user_type === "architect" ||
                      user?.user_type === "brand") && (
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`/${user.user_type}/product-library`)
                          }
                          className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "properties"
                            ? "text-primary"
                            : "text-gray-900"
                            }`}
                          onMouseEnter={() => setAccHover("properties")}
                          onMouseLeave={() => setAccHover(null)}
                        >
                          Product Library
                        </NavDropdown.Item>
                      )}
                    {/* {user.user_type !== "customer" && (
                      <NavDropdown.Item
                        onClick={() =>
                          navigate(
                            user.user_type === "builder"
                              ? "/builder/account/settings"
                              : `/${user.user_type}/account/settings`
                          )
                        }
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "settings"
                          ? "text-primary"
                          : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("settings")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Settings
                      </NavDropdown.Item>
                    )} */}
                    <NavDropdown.Item
                      onClick={handleLogout}
                      className={`border-top body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "logout" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("logout")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LocationModal
        showLocationModal={showLocationModal}
        setShowLocationModal={setShowLocationModal}
        allLocations={allLocations}
        setCurrentLocation={setCurrentLocation}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default NavBar;
