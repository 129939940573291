import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import { for_professionals_1, for_professionals_2 } from "assets";

const ProfessionalHome = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);

  const portfolioSteps = [
    { id: 1, title: "Create your professional account", description: "Showcase all your work, details and awards with a single click. Let clients find you!" },
    { id: 2, title: "Badges to promote your presence", description: "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform." },
    { id: 3, title: "Badges to promote your presence", description: "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform." }
  ]

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return (
        <div className="custom-dot mt-3" />
      );
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center px-0"
        style={{ minHeight: "100vh" }}
      >
        <Container className={`p-0 ${screenHeight <= 800 ? "mt-5" : ""}`}>
          <div className="h1 px-lg-5 inter-600">
            “Market your brand, showcase your work and get credible clients from all over India through Interiokeys!”
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Gain an edge over competitors by collaborating with Interiokeys, where we give you support from start to finish.
          </div>
        </Container>
        <div className="d-flex justify-content-center bg-brand-500-half w-100 mx-0">
          <div className="mx-2">
            <Image src={for_professionals_1} alt="For Professioanls image 1" className="w-100" />
          </div>
          <div className="mx-2">
            <Image src={for_professionals_2} alt="For Professioanls image 1" className="w-100" />
          </div>
        </div>
      </Container>
      <Container className="d-flex flex-column justify-content-center mb-5 pb-5">
        <h2 className="inter-600">Step 1: Start Filling your Portfolio!</h2>
        <Row className="mt-4 row-cols-3">
          {portfolioSteps.map((step) => {
            return (
              <Col>
                <div key={step.id} className="p-2 text-center border border-gray-100" style={{ borderRadius: 6 }}>
                  <h3 className="inter-600">{step.title}</h3>
                  <div className="body-text1 inter-400 text-gray-600">{step.description}</div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
      {/* <Container fluid className="bg-gray-25 py-5">
        <Container
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{ minHeight: "40vh" }}
        >
          <div className="h2 text-center inter-600 m-0">
            “All the best brands Available At your fingertips”
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Create top quality inspirations for brands
          </div>
          <div>
            <Button
              className="inter-500 text-white mb-4"
              style={{ borderRadius: 3 }}
              onClick={() => navigate("/brands")}
            >
              Explore Our Brands Partners!
            </Button>
          </div>
          <Breakpoint large up>
            {brands && (
              <Banners
                items={brands?.map((item) => ({
                  id: item.id,
                  title: item?.company_name,
                  image: item?.image,
                }))}
                speed={2000}
              />
            )}
          </Breakpoint>
          <Breakpoint medium down>
            <Row className="mt-3 row-cols-2">
              {brands && brands?.slice(0, 4)?.map((item, index) => {
                return (
                  <Col key={index} className={`${index % 2 === 0 ? "pe-1" : "ps-1"}`}>
                    <Card
                      className="d-flex justify-content-center align-items-center my-1 shadow-none border-gray-100"
                      style={{ height: 42, borderRadius: 3 }}
                      onClick={() => navigate(`/brands/${item.slug}`)}
                    >
                      <Image
                        src={item?.image}
                        alt={item?.title}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </Card>
                  </Col>
                )
              })}
            </Row>
            <div className="text-gray-600 body-title">and many more..</div>
          </Breakpoint>
        </Container>
      </Container> */}
      {/* <ArchitectsForDisplay recommendedArchitects={recommendedArchitects} /> */}
      {/* <HowItWorks /> */}
      {/* <Container className="my-5 py-5 d-flex flex-column justify-content-center align-items-center">
        <div className="h2 inter-600 text-center">
          Streamline your home interior process through Interiokeys!
        </div>
        <div className="text-gray-600 body-title inter-400 text-center mt-1 mb-3">
          At Interiokeys, you can choose from the 2 types of services provided.
        </div>
        <ServiceCard />
      </Container> */}
      {/* <Container fluid className="bg-gray-25 py-5 my-5">
        <Container className="d-flex flex-column justify-content-center align-items-center text-center">
          <div className="h2 inter-600 text-center">
            Trending Projects in your City!
          </div>
          <div className="text-gray-600 body-title inter-400 text-center mt-1 mb-3">
            At Interiokeys, you can choose from the 2 types of services provided.
          </div>
          {recommendedProjects && (
            <div className="w-100">
              <Slider {...sliderSettings}>
                {recommendedProjects?.map((project, index) => (
                  <div key={index} className="d-flex justify-content-center p-1">
                    <ProjectCard
                      id={project.id}
                      slug={project.slug}
                      project_name={project.project_name}
                      image={project.rooms[0]?.media[0]?.file}
                      type={`${project.bhk}, ${project.project_area}`}
                      architect={project?.company_name}
                      builders={project.property_data?.builder_data?.company_name}
                      boxShadow="none"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </Container>
      </Container> */}
      {/* <Container className="d-flex justify-content-center align-items-center my-5 py-5">
        <div className="w-100">
          <h2 className="text-start inter-600 mb-4">
            Listen to What Our Clients Say!
          </h2>
          <h3 className="inter-600">Interiokeys Clients</h3>
          <Row className="mb-4">
            {clientTestimonials && clientTestimonials?.length > 0 ? (
              clientTestimonials?.map((testimonial, index) => (
                <Col key={index} md={4} className="mb-3">
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 140 }}
              >
                No testimonials available
              </div>
            )}
          </Row>
          <h3 className="inter-600">Interiokeys Partners</h3>
          <Row>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {builderTestimonials && (
                <VerticalSlider items={builderTestimonials} rtl={false} />
              )}
              <span className="inter-600 mt-4">Builders</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {architectTestimonials && (
                <VerticalSlider items={architectTestimonials} rtl={true} />
              )}
              <span className="inter-600 mt-4">Architects</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {brandTestimonials && (
                <VerticalSlider items={brandTestimonials} rtl={false} />
              )}
              <span className="inter-600 mt-4">Brands</span>
            </Col>
          </Row>
        </div>
      </Container> */}
    </Container>
  );
};

export default ProfessionalHome;
