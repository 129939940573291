import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import Pagination from "components/Pagination";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ShareModal from "components/General/ShareModal";
import { project_switch_card } from "assets";

import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

const InspirationsCreated = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const user = useSelector((state) => state.auth.user);

    // const xsmall = useMediaQuery({ query: "(min-width: 400px)" });
    // const small = useMediaQuery({ query: "(min-width: 576px)" });
    // const medium = useMediaQuery({ query: "(min-width: 768px)" });
    // const large = useMediaQuery({ query: "(min-width: 992px)" });
    // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
    // const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
    // const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

    let params = new URLSearchParams(document.location.search);
    let property = params.get("property");
    let bhk = params.get("bhk");
    let sortBy = params.get("sort_by");

    const [properties, setProperties] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showShareProject, setShowShareProject] = useState(false);

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    const layouts = [
        { id: 1, label: "2 BHK", value: "2BHK" },
        { id: 2, label: "2.5 BHK", value: "2.5BHK" },
        { id: 3, label: "3 BHK", value: "3BHK" },
        { id: 4, label: "3.5 BHK", value: "3.5BHK" },
        { id: 5, label: "4 BHK", value: "4BHK" },
    ]

    const fetchProperties = async () => {
        try {
            const response = await authActions.fetchPropertiesByBuilderNoPagination(user.id);
            if (response.data) {
                setProperties(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeFilters = (value, type) => {
        let filters = "?"
        // Property
        if (type === "property") {
            filters += `&property=${value}`;
        } else {
            filters += `&property=${property}`;
        }

        // Layout
        if (type === "bhk") {
            filters += `&bhk=${value}`;
        } else {
            filters += `&bhk=${bhk}`;
        }

        // Sort By
        if (type === "sort_by") {
            filters += `&sort_by=${value}`;
        } else {
            filters += `&sort_by=${sortBy}`;
        }

        navigate(`/projects${filters}`);
    }

    const fetchProjects = async (page) => {
        setLoading(true);
        try {
            const response = await authActions.fetchProjects(
                page,
                user.id,
                null,
                property,
                bhk,
                sortBy
            );
            if (response.data) {
                setProjects(response.data?.results);
                setTotalPages(Math.ceil(response.data.count / 12));
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchProjects();
    }, [property, bhk, sortBy]);

    useEffect(() => {
        fetchProjects(currentPage);
    }, [currentPage]);

    useEffect(() => {
        fetchProperties();
    }, []);

    if (slug) {
        return <Outlet />;
    }

    return (
        <Container fluid>
            <Container className="mt-5 pt-5">
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex">
                        {(property && property !== "null") ?
                            <div
                                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            >
                                <span className="me-2">
                                    {properties.filter((prop) => prop.slug === property).length > 0
                                        ?
                                        properties.filter((prop) => prop.slug === property)[0].property_name
                                        :
                                        property
                                    }
                                </span>
                                <div className="cursor-pointer" onClick={() => onChangeFilters(null, "property")}>
                                    <IoClose size={20} />
                                </div>
                            </div>
                            :
                            <Form.Select
                                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                onChange={(e) => onChangeFilters(e.target.value, "property")}
                                value={property && property !== "null" ? property : ""}
                            >
                                <option value="" disabled>
                                    Property Name
                                </option>
                                {properties.map((property) => {
                                    return (
                                        <option value={property.slug}>{property.property_name}</option>
                                    )
                                })}
                            </Form.Select>
                        }
                        {(bhk && bhk !== "null") ?
                            <div
                                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            >
                                <span className="me-2">
                                    {layouts.filter((lay) => lay.value === bhk)[0].label}
                                </span>
                                <div className="cursor-pointer" onClick={() => onChangeFilters(null, "bhk")}>
                                    <IoClose size={20} />
                                </div>
                            </div>
                            :
                            <Form.Select
                                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                                value={bhk && bhk !== "null" ? bhk : ""}
                            >
                                <option value="" disabled>
                                    Floor Layout
                                </option>
                                {layouts.map((layout) => {
                                    return (
                                        <option value={layout.value}>{layout.label}</option>
                                    )
                                })}
                            </Form.Select>
                        }
                        {/* <Form.Select
              className="bg-transparent text-gray-700 me-2 cursor-pointer"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              // onChange={(e) => setTheme(e.target.value)}
              value=""
            >
              <option value="" disabled>
                Interior Theme
              </option>
              <option value="modern">Modern</option>
              <option value="contemporary">Contemporary</option>
              <option value="traditional">Traditional</option>
              <option value="neo_classical">Neo-Classical</option>
              <option value="eclectic">Eclectic</option>
              <option value="tropical">Tropical</option>
              <option value="mediterranean">Mediterranean</option>
            </Form.Select> */}
                        {/* <Form.Select
              className="bg-transparent text-gray-700 me-2 cursor-pointer"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              // onChange={(e) => setTheme(e.target.value)}
              value=""
            >
              <option value="" disabled>
                Project Budget
              </option>
              <option value="modern">{`<500`}</option>
              <option value="contemporary">{`>500 & <10000`}</option>
              <option value="traditional">{`>10000 & <50000`}</option>
            </Form.Select> */}
                    </div>
                    <div>
                        <div className="custom-select-wrapper">
                            <Form.Select
                                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                                value={sortBy && sortBy !== "null" ? sortBy : ""}
                            >
                                <option value="" disabled>Sort By</option>
                                <option value="created_at">Featured (New to Old)</option>
                                <option value="-created_at">Featured (Old to New)</option>
                            </Form.Select>
                            <CgSortAz size={24} className="custom-dropdown-icon" />
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center w-100"
                            style={{ minHeight: "50vh" }}
                        >
                            <Spinner />
                        </div>
                    ) : projects?.length > 0 ? (
                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
                            <Row className="row-cols-3 w-100">
                                {projects?.map((item, index) => (
                                    <Col
                                        key={index}
                                        className={`d-flex flex-column py-2 mb-2 justify-content-start ${index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : ""}`}
                                    >
                                        <ProjectCard
                                            {...item}
                                            id={item.id}
                                            project_name={item?.project_name}
                                            image={item?.rooms[0]?.media[0]?.file}
                                            // image={item?.image}
                                            // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                                            type={`${item?.bhk}, ${item?.project_area}`}
                                            architect={item?.company_name}
                                            location={item?.property_data?.location}
                                            builders={item?.property_data?.builder_data?.company_name}
                                            className="mx-0 flex-grow"
                                        />
                                        <ShareModal
                                            show={showShareProject}
                                            onHide={() => setShowShareProject(false)}
                                            // id={id}
                                            // image={image}
                                            // title={`${project_name} / ${type}`}
                                            urlPath={
                                                item?.slug !== "undefined"
                                                    ? `/projects/${item?.slug}`
                                                    : null
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) : (
                        <div
                            className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
                            style={{ height: '60vh' }}
                        >
                            No projects available
                        </div>
                    )}
                </div>
            </Container>
        </Container >
    );
};

export default InspirationsCreated;
