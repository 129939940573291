import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import { FaFile, FaFileImage, FaFilePdf } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInput from "components/DragInput/DropFileInput";
import UploadingFiles from "components/DragInput/UploadingFiles";
import { FiRefreshCcw, FiTrash2 } from "react-icons/fi";

function EditProperty() {
  const { propertyId } = useParams();
  const navigate = useNavigate();

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const propertyInfoRef = useRef();
  const [propertyData, setPropertyData] = useState(null);
  const [hover, setHover] = useState(false);
  const [projectTypes, setProjectTypes] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [layouts, setLayouts] = useState([]);

  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [selectedConstructionStatus, setSelectedConstructionStatus] = useState("");

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);

  const constructionStatuses = [
    { id: 1, label: "Pre-Construction Phase", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Completed", value: "COMPLETED" },
  ];

  const fetchPropertyData = async () => {
    const response = await authActions.fetchPropertyById(propertyId);
    try {
      if (response.data) {
        setPropertyData(response.data);
        setSelectedProjectType(response.data.project_type);
        setSelectedConstructionStatus(response.data.construction_status);
        setMediaFiles(response.data?.media_files);
        setLayouts(response.data?.layout_type_data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (propertyId) {
      fetchPropertyData();
    }
  }, [propertyId])

  const fetchProjectTypes = async () => {
    const response = await authActions.fetchProjectTypes();
    try {
      if (response.data) {
        setProjectTypes(response.data?.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPropertyImage(file);
    }
  };

  const addLayout = () => {
    setLayouts([
      ...layouts,
      {
        id: layouts.length + 1,
        layout: "",
        area: "",
      },
    ]);
  };

  const deleteLayout = (index) => {
    setLayouts([...layouts.slice(0, index), ...layouts.slice(index + 1)]);
  };

  const handleInputChange = (layoutId, field, value) => {
    setLayouts(
      layouts.map((layout) =>
        layout.id === layoutId ? { ...layout, [field]: value } : layout
      )
    );
  };

  const handlePropertyDetails = () => {
    const formData = new FormData(propertyInfoRef.current);
    let isError;
    let data = {};
    formData.forEach((value, key) => {
      console.log(key, value);
      if (!value) {
        isError = true;
      } else {
        data[key] = value;
      }
    });

    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    } else {
      handlePropertyUpdate();
    }
  };

  const handlePropertyUpdate = async () => {
    const propertyDetails = {
      property: propertyData,
      layouts: layouts,
      mediaFiles: mediaFiles,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(propertyDetails));
    if (selectedPropertyImage) {
      formData.append("property_img", selectedPropertyImage);
    }
    try {
      const response = await authActions.updateProperty(propertyId, formData);
      if (response.data) {
        notify("Property Updated!", "success");
        if (completeProjectFiles.length > 0) {
          setIsFileUploading(true);
          setParentId(response.data.id);
        } else {
          navigate(`/builder/properties`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  return (
    <Container className={`mt-5 pt-4 d-flex flex-column justify-content-center ${large ? "px-0" : ""}`} style={{ minHeight: "100vh" }}>
      <div className="d-flex justify-content-between align-items-center mt-5 pt-4">
        <h3 className="text-gray-900 inter-600 m-0">Edit Property Details</h3>
        <div className="d-flex">
          <Button
            className="d-flex align-items-center text-white body-text2 me-2"
            style={{ borderRadius: 3 }}
            // onClick={handlePropertyUpdate}
            onClick={handlePropertyDetails}
          >
            Save Changes
          </Button>
          <Button
            className="bg-transparent border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-4 w-100">
        <div className={`p-3 me-2 border border-gray-100 ${large ? "w-50" : "w-100"}`} style={{ borderRadius: 6 }}>
          <h4 className="fw-bold">Property Details</h4>
          <div className="mt-4">
            <Form ref={propertyInfoRef}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Image<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Card
                  className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
                  style={{ width: 180, height: 150, border: "1px dashed #BDC1C6", borderRadius: 6 }}
                >
                  <Image
                    className="p-0"
                    style={{ borderRadius: 6, objectFit: "cover" }}
                    maxWidth={180}
                    height="100%"
                    src={selectedPropertyImage ? URL.createObjectURL(selectedPropertyImage) : propertyData?.property_img}
                    alt="avatar"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  />
                  {hover &&
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: 6 }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={() => document.getElementById("propertyImageUpload").click()}
                    >
                      <FiRefreshCcw size={20} color="white" />
                      <span className="mt-1">Change Property Image</span>
                    </div>
                  }
                  <input
                    type="file"
                    id="propertyImageUpload"
                    style={{ display: "none" }}
                    onChange={handlePropertyImageChange}
                  />
                </Card>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  name="property_name"
                  placeholder="Enter property name"
                  defaultValue={propertyData?.property_name}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Number of Flats<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="No. of flats"
                  name="flats"
                  defaultValue={propertyData?.flats}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Possession Date<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Possession Date"
                  name="property_year"
                  defaultValue={propertyData?.property_year}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Location<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Enter location"
                  name="location"
                  defaultValue={propertyData?.location}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Project Type<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Enter location"
                  name="project_type"
                  onChange={(e) => setSelectedProjectType(e.target.value)}
                  value={selectedProjectType}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <option value="">Select</option>
                  {projectTypes?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Construction Status<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Select Status"
                  name="construction_status"
                  onChange={(e) => setSelectedConstructionStatus(e.target.value)}
                  value={selectedConstructionStatus}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <option value="">Select</option>
                  {constructionStatuses.map((conStatus) => {
                    return (
                      <option value={conStatus.value}>{conStatus.label}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        </div >
        <div className={`p-3 border border-gray-100 ${large ? "ms-2 w-50" : "mt-4 w-100"}`} style={{ borderRadius: 6 }}>
          <h4 className="fw-bold">Property Details</h4>
          <div className="my-4">
            {layouts.map((layout, index) => (
              <div key={index}>
                <Row className="w-100 mt-2 me-2">
                  <Col xs={5} md={5} className="ps-3">
                    <Form.Group className="mb-3 mb-md-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="layout"
                        placeholder="2BHK"
                        defaultValue={layout.layout}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        onChange={(e) =>
                          handleInputChange(
                            layout.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={6} className="px-1">
                    <Form.Group className="mb-3 mb-sm-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Area<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <InputGroup
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        <Form.Control
                          required
                          className="py-2 body-text2 border-0"
                          type="text"
                          name="area"
                          placeholder="200"
                          defaultValue={layout.area}
                          style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                          onChange={(e) =>
                            handleInputChange(
                              // block.id,
                              layout.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                        />
                        <InputGroup.Text className="body-text3 border-0 px-2 bg-gray-50" style={{ borderRadius: 3, margin: 3 }}>
                          Sq. Ft.
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={1} md={1} className="d-flex jsutify-content-end">
                    {index > 0 && (
                      <div className="d-flex align-items-end">
                        <Button
                          className="d-flex align-items-center rounded-btn bg-gray py-2"
                          onClick={() =>
                            deleteLayout(index)
                          }
                        >
                          <FiTrash2 size={18} />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
            <div
              className="mt-2 ms-2 body-text3 inter-500 cursor-pointer"
              onClick={() => addLayout()}
            >
              <span style={{ borderBottom: "1px solid #000000" }}>Add another layout</span>
            </div>
          </div>
          <div>
            <h4 className="fw-bold">Property Files</h4>
            <div className="text-gray-600 body-text3 mb-2">Add all layout files relevant to designers here. Try to label them according to standards.</div>
            <DropFileInput
              type="property"
              files={completeProjectFiles}
              allowMultiple
              className="bg-white border-dark"
              onFileChange={(files) => setCompleteProjectFiles(files)}
            />
            <div className="inter-600 mt-3 mb-2">Files Added:</div>
            {mediaFiles.map((file, index) => {
              return (
                <Card
                  key={index}
                  className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
                  style={{ borderRadius: 3 }}
                >
                  <div className="d-flex align-items-center">
                    {file?.extension === ".pdf" ?
                      <FaFilePdf className="text-primary-500 me-2" size={24} />
                      :
                      (file?.extension === ".png" || file?.extension === ".jpg" || file?.extension === ".jpeg" || file?.extension === ".webp" || file?.extension === ".heic") ?
                        <FaFileImage className="me-2" size={24} color="#83c8e4" />
                        :
                        <FaFile className="text-primary-500 me-2" size={24} />
                    }
                    <div className="d-flex flex-column">
                      <span className="body-text2 text-gray-900">{file?.temp_file_name}</span>
                      <span className="text-gray-600"
                        style={{ fontSize: 10 }}
                      >
                        {file?.size}
                      </span>
                    </div>
                  </div>
                  <FiTrash2 size={20} color="#3C4043" />
                </Card>
              )
            })}
          </div>
        </div >
      </div >
      <UploadingFiles
        show={isFileUploading}
        files={completeProjectFiles}
        setShow={setIsFileUploading}
        parentId={parentId}
        app_label={"property"}
        model_name={"property"}
      />
    </Container >
  );
}

export default EditProperty;
