import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerShareButton,
} from "react-share";

const ShareModal = ({
  urlPath,
  modalTitle,
  id,
  image,
  title,
  show,
  onHide,
}) => {
  const hostUrl = process.env.REACT_APP_HOST_URL;
  const [shareUrl, setShareUrl] = useState(window.location.href);

  useEffect(() => {
    if (urlPath) {
      setShareUrl(`${hostUrl}${urlPath}`);
    }
  }, [urlPath]);

  return (
    <Modal
      show={show}
      className="overflow-hidden"
      dialogClassName="shareModal"
      onHide={onHide}
    >
      {/* <Modal.Header className="border-0 px-md-5 pt-md-4">
        <HeaderBar title="Share product" handleBack={onHide} />
      </Modal.Header> */}
      {modalTitle && (
        <Modal.Header closeButton className="border-0 px-md-5 pt-md-4">
          <Modal.Title className="text-bold">{modalTitle}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="p-2">
        {(id || title || image) && (
          <ShareItemDetailsCard
            id={id}
            title={title}
            image={image}
            imageHeight={80}
          />
        )}
        <div className="d-flex justify-content-center align-items-center g-4 mb-4 ps-3 flex-wrap">
          <EmailShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <EmailIcon size={32} round={true} />
          </EmailShareButton>

          <FacebookShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>

          <FacebookMessengerShareButton
            url={shareUrl}
            className="ms-0 me-4 my-3"
          >
            <FacebookMessengerIcon size={32} round={true} />
          </FacebookMessengerShareButton>

          <LinkedinShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>

          <TelegramShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>

          <TwitterShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>

          <WhatsappShareButton url={shareUrl} className="ms-0 me-4 my-3">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </div>
        {/* <Row xs={7} className="d-flex align-items-center g-4 mb-4 ps-3 pb-4">
          <Col className="d-flex border">
            <EmailShareButton url={shareUrl} className="m-3 ms-0">
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </Col>
          <Col className="d-flex border">
            <FacebookShareButton
              url={shareUrl}
              className="m-3 ms-0"
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Col>
          <Col className="d-flex border">
            <FacebookMessengerShareButton
              url={shareUrl}
              className="m-3 ms-0"
            >
              <FacebookMessengerIcon size={32} round={true} />
            </FacebookMessengerShareButton>
          </Col>
          <Col className="d-flex border">
            <LinkedinShareButton
              url={shareUrl}
              className="m-3 ms-0"
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </Col>
          <Col className="d-flex border">
            <TelegramShareButton
              url={shareUrl}
              className="m-3 ms-0"
            >
              <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
          </Col>
          <Col className="d-flex border">
            <TwitterShareButton url={shareUrl} className="m-3 ms-0">
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Col>
          <Col className="d-flex border">
            <WhatsappShareButton
              url={shareUrl}
              className="m-3 ms-0"
            >
              <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
          </Col>
        </Row> */}
        <p className="text-center text-gray-900" style={{ fontSize: 12 }}>
          Interiokeys.com
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;

const ShareItemDetailsCard = ({ title, image, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="d-flex flex-column align-items-center px-3 pt-2 pb-0 flex-row text-start border-0"
      style={{ boxShadow: "none" }}
      onClick={() => {
        if (navigateTo) navigate(navigateTo);
      }}
    >
      {image && (
        <div style={{ width: 200 }}>
          <Card.Img
            variant="top"
            src={image}
            alt="Interiokeys"
            width={100}
            className="bg-white"
            style={{ objectFit: "contain" }}
          />
        </div>
      )}
      <Card.Body className="w-100">
        <Card.Title className="text-center h4 inter-700 text-dark">
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
};
