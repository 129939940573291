import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import Review from "components/Review/Review";
import ProfilePropertyCard from "components/Cards/ProfilePropertyCard";
import ShareModal from "components/General/ShareModal";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import PropertyGridCard from "components/Cards/PropertyGridCard";
import { useSelector } from "react-redux";
import { LuPencilLine } from "react-icons/lu";
import ProjectCard from "components/Cards/ProjectCard";
import { notify } from "utilities/notifications/Notify";

const BuilderDetails = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [properties, setProperties] = useState(null);
  const [builderDetails, setBuilderDetails] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [showShareProject, setShowShareProject] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const fetchData = async () => {
    setProjectsLoading(true);
    // fetch builder data
    try {
      const resBuilders = await authActions.fetchBuildersByTypeSlug(
        detailedSlug
      );
      console.log(resBuilders?.data);
      if (resBuilders?.data) {
        console.log(resBuilders.data);
        setBuilderDetails(resBuilders.data);
      }
      // fetch properties by builder user
      // const resProperties = await authActions.fetchPropertiesByBuilderUser(user.id);
      // console.log(resProperties?.data?.results);
      // if (resProperties?.data?.results) {
      //   setProperties(resProperties?.data?.results);
      // }

      // fetch projects by builder user
      const response = await authActions.fetchProjectsByBuilderUser(user.id);
      if (response.data?.results) {
        setProjects(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }

      setProjectsLoading(false);
      // fetch reviews by architect
      const resReviews = await authActions.fetchReviewsByArchitectUser(user.id);
      console.log(resReviews?.data?.results);
      if (resReviews?.data?.results) {
        setReviews(resReviews);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        builderDetails.user
      );
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        builderDetails.user
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user.id,
        builderDetails.user
      );
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (detailedSlug) {
      fetchData();
    } else {
      navigate(-1);
    }
  }, [detailedSlug]);

  useEffect(() => {
    if (builderDetails?.id) {
      checkProfessionalIfSaved();
    }
  }, [builderDetails]);

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <div className="d-flex justify-content-center bg-gray-25 geist">
        <Container className="d-flex flex-column flex-lg-row align-items-start p-3 border-bottom m-0">
          <div className="w-100" style={{ order: large ? 1 : 2 }}>
            <div className="d-flex align-items-center w-100">
              <div className="h2 inter-600 w-100 text-capitalize">
                {builderDetails?.company_name ??
                  detailedSlug?.split("-").join(" ")}
              </div>
              <div className="d-flex ms-auto me-lg-5">
                <Button
                  className="d-flex justify-content-center align-items-center social-btn py-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowShareProject(true);
                  }}
                >
                  <FiShare2 size={18} />
                </Button>
                {user &&
                  user?.user_type !== "builder" &&
                  user?.user_type !== "architect" &&
                  user?.user_type !== "brand" && (
                    <Button
                      className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${
                        isLiked ? "bg-primary text-white" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        isLiked
                          ? handleUnsaveProfessional()
                          : handleSaveProfessional();
                      }}
                    >
                      <FaRegHeart size={18} />
                    </Button>
                  )}
              </div>
            </div>
            <div className="body-text inter-600">Builder</div>
            <div>
              {`${builderDetails?.project_count ?? 0}+ Years | ${
                builderDetails?.project_count ?? 0
              }+ Projects | ${builderDetails?.no_of_cities ?? 0}+ Cities`}
            </div>
            <div>
              <span className="body-text inter-600">Location:</span>{" "}
              {builderDetails?.location}
            </div>
            <div className="d-flex align-items-center body-text inter-600">
              Rating:{" "}
              {builderDetails?.rating ? (
                <div>
                  {builderDetails?.rating}
                  <FaStar color="#D95656" />
                </div>
              ) : (
                "-"
              )}
              <FaStar color="#D95656" />
            </div>
            <div>
              <span className="body-text inter-600">Typical Job Cost:</span>{" "}
              {builderDetails?.job_cost_minimum}-
              {builderDetails?.job_cost_maximum}
            </div>
            <div>
              <span className="body-text inter-600">Properties made:</span>{" "}
              {builderDetails?.properties
                ? builderDetails?.properties
                    .map((obj) => obj?.property_name)
                    .join(", ")
                : "-"}
            </div>
            <Button
              variant="primary"
              className="my-2 p-2"
              style={{
                fontWeight: "600",
                color: "#fff",
                border: 0,
                fontSize: 14,
              }}
              onClick={() => navigate(-1)}
            >
              <LuPencilLine className="me-2" />
              Edit Profile
            </Button>
            {/* <div>
              <span className="body-text inter-600">Projects Count:</span>{" "}
              {builderDetails?.project_count}
            </div>
            <div>
              <span className="body-text inter-600">Number of Cities:</span>{" "}
              {builderDetails?.no_of_cities}
            </div> */}
          </div>
          <div
            className={`d-flex align-items-center mt-2 ${
              large ? "justify-content-end" : "justify-content-start mb-3"
            }`}
            style={{ order: large ? 2 : 1 }}
          >
            <div
              className="d-flex justify-content-center align-items-center bg-white overflow-hidden"
              style={{ width: 200, height: 200, borderRadius: 12 }}
            >
              {builderDetails?.user_data?.profile_picture ? (
                <img
                  className="bg-white ms-auto"
                  width={200}
                  height={200}
                  style={{
                    // border: "1px solid #D0D5DD",
                    objectFit: "cover",
                  }}
                  src={builderDetails?.user_data?.profile_picture}
                  alt="Builder's Profile"
                />
              ) : (
                <div>No profile pic</div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <Container className="pt-4 pb-3">
        <Row>
          {/* {properties?.map((item) =>
            item?.inspirations?.map((elem, index) => (
              <Col
                key={index}
                lg={4}
                className="d-flex justify-content-around my-3"
              >
                <ProfilePropertyCard
                  id={elem.id}
                  image={elem.spaces[0].image}
                  name={item.name}
                  project_type={item.project_type}
                  layout_types={item.layout_types}
                  location={item.location}
                  builder={item.builder}
                />
              </Col>
            ))
          )} */}
          {projectsLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "50vh" }}
                >
                  <Spinner />
                </div>
              ) : projects?.length > 0 ? (
                <div className="mb-4">
                  <div className="h3 inter-600">{`Projects Created(${projects?.length})`}</div>
                  <Row>
                    {projects.map((item, index) => (
                      <Col
                        key={index}
                        sm={6}
                        md={6}
                        lg={4}
                        className="d-flex justify-content-around my-3"
                      >
                        <ProjectCard
                          id={item.id}
                          project_name={item?.project_name}
                          image={item?.rooms[0]?.media[0]?.file}
                          // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                          type={`${item?.bhk}, ${item?.project_area}`}
                          architect={item?.company_name}
                          location={item?.property_data?.location}
                          builders={
                            item?.property_data?.builder_data?.company_name
                          }
                        />
                        <ShareModal
                          show={showShareProject}
                          onHide={() => setShowShareProject(false)}
                          // id={id}
                          // image={image}
                          // title={`${project_name} / ${type}`}
                          urlPath={
                            item?.slug !== "undefined"
                              ? `/projects/${item?.slug}`
                              : null
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div>
                  <div className="h3 inter-600">Projects</div>
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                    style={{ height: 160 }}
                  >
                    No projects available
                  </div>
                </div>
              )}
              {/* {properties && (
                <div className="mb-4">
                  <div className="h3 inter-600">{`Properties Created(${properties?.length})`}</div>

                  <Row
                    md={2}
                    lg={3}
                    className="my-4 signup-card flex-wrap"
                    style={{ boxShadow: "none" }}
                  >
                    {properties?.map((property, index) => (
                      <Col
                        key={index}
                        md={6}
                        lg={4}
                        className="mb-4"
                        // style={{ flexGrow: 1 }}
                      >
                        <PropertyGridCard
                          key={index}
                          {...property}
                          builder={property?.builder_data?.company_name}
                          project_type={property.project_type_data.title}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )} */}
            </div>
          )}
        </Row>
        <div className="my-4">
          <div className="h3 inter-600">Add Review</div>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="I love interiokeys because..."
            />
          </Form.Group>
          <div className="w-100 my-3 d-flex justify-content-end">
            <Button className="text-white">Submit Review</Button>
          </div>
          {/* <div className="h4 inter-600 mb-3">Reviews and Ratings</div> */}
          <div className="h3 inter-600">Reviews and Ratings</div>
          {reviews?.length > 0 ? (
            reviews.map((review, index) => <Review key={index} {...review} />)
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No reviews available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default BuilderDetails;
