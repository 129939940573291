import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { AccountEditForm, ChangePasswordForm } from "components";
import PropertyGridCard from "components/Cards/PropertyGridCard";
import * as authActions from "reduxFiles/actions/authActions";

const BuilderPersonalProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [properties, setProperties] = useState();

  const fetchProperties = async () => {
    setPropertiesLoading(true);
    try {
      const response = await authActions.fetchPropertiesByBuilderUser(user.id);
      if (response.data) {
        setProperties(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setPropertiesLoading(false);
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0"
      style={{ minHeight: "100vh" }}
    >
      <Container>
        <h3 className="inter-600 text-gray-900 py-5 mb-0">My Profile</h3>
        <AccountEditForm />
        <ChangePasswordForm />
        {/* <Library /> */}
        {propertiesLoading ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            {properties && (
              <div className="mb-5">
                <h3>{`Properties Created(${properties?.length})`}</h3>
                <Row
                  md={2}
                  lg={3}
                  className="my-4 signup-card flex-wrap"
                  style={{ boxShadow: "none" }}
                >
                  {properties.map((property, index) => (
                    <Col key={index} className="mb-4">
                      <PropertyGridCard
                        key={index}
                        {...property}
                        builder={property?.builder_data?.company_name}
                        project_type={property.project_type_data.title}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        )}
      </Container>
    </Container>
  );
};

export default BuilderPersonalProfile;
