import ProductCard from "components/Cards/ProductCard";
import ProductListCard from "components/Cards/ProductListCard";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Pagination,
  Row,
} from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import { FaCircle, FaCircleCheck } from "react-icons/fa6";
import { IoIosRadioButtonOn } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";

const AddProductsModal = ({
  show,
  setShow,
  selectedRoomtypes,
  setSelectedRoomtypes,
  roomtypeIndex,
  activeRoomIndex,
  setActiveRoomIndex,
}) => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [activeRoomIndex, setActiveRoomIndex] = useState(0);
  // const [currentRoomtypeRooms, setCurrentRoomtypeRooms] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [sortBy, setSortBy] = useState("");

  const handleCheckboxChange = (pid) => {
    if (checkedProducts.includes(pid)) {
      console.log(checkedProducts.filter((id) => id !== pid));
    } else {
      console.log([...checkedProducts, pid]);
    }
    setCheckedProducts(() => {
      // if (checkedProducts.some((p) => p.id === prod.id)) {
      //   return checkedProducts.filter((p) => p.id !== prod.id);
      // } else {
      //   return [...checkedProducts, prod.id];
      // }
      if (checkedProducts.includes(pid)) {
        return checkedProducts.filter((id) => id !== pid);
      } else {
        return [...checkedProducts, pid];
      }
    });
  };

  const fetchProductTypes = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchProductTypes();
      if (response.data) {
        setCategories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchBrands = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchProducts = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(page);
      if (response.data) {
        setProducts(response?.data?.results);
        // setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchSearchedProducts = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(
        page,
        searchCategory,
        searchBrand
      );
      if (response.data) {
        setProducts(response?.data?.results);
        // setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedRoomtypes[roomtypeIndex]?.rooms) {
      console.log(selectedRoomtypes[roomtypeIndex]?.rooms);
      const temp = [
        ...selectedRoomtypes.slice(0, roomtypeIndex),
        {
          ...selectedRoomtypes[roomtypeIndex],
          rooms: [
            ...selectedRoomtypes[roomtypeIndex]?.rooms.slice(
              0,
              activeRoomIndex
            ),
            {
              ...selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex],
              products: checkedProducts,
            },
            ...selectedRoomtypes[roomtypeIndex]?.rooms.slice(
              activeRoomIndex + 1
            ),
          ],
        },
        ...selectedRoomtypes.slice(roomtypeIndex + 1),
      ];
      console.log(temp);
      console.log(selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]);
      if (selectedRoomtypes.length > 0) {
        setSelectedRoomtypes(temp);
      }
    }
  }, [checkedProducts]);

  useEffect(() => {
    console.log(selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]);
    console.log(
      "Current roomtype products: \n",
      selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]?.products
    );
    if (selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]?.products) {
      setCheckedProducts(
        selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex].products
      );
    }
  }, [roomtypeIndex, activeRoomIndex]);

  useEffect(() => {
    fetchSearchedProducts();
  }, [searchBrand, searchCategory]);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchProductTypes();
    fetchBrands();
  }, []);

  const FilterFields = () => {
    return (
      <div>
        <Form.Group className="mb-3">
          <InputGroup className="border rounded">
            <InputGroup.Text className="border-0">
              <CiSearch />
            </InputGroup.Text>
            <Form.Control
              className="py-2 ps-2 border-0"
              size="sm"
              placeholder="Search for projects, images, etc"
            />
          </InputGroup>
        </Form.Group>
        <div className="d-flex mb-3">
          {categories?.length > 0 && (
            <div className="d-flex">
              <Form.Select
                defaultValue=""
                className="bg-gray-25 me-3"
                onClick={(e) => setSearchCategory(e.target.value)}
              >
                <option value="" disabled>
                  Category
                </option>
                {categories?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
          {brands?.length > 0 && (
            <div className="d-flex">
              <Form.Select
                defaultValue=""
                className="bg-gray-25 me-3"
                onClick={(e) => setSearchBrand(e.target.value)}
              >
                <option value="" disabled>
                  Brand
                </option>
                {brands?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.company_name}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      fullscreen
      className={`add-product-modal bg-white ${
        large ? "overflow-hidden" : "overflow-hidden"
      }`}
      onHide={() => setShow(false)}
      style={{ maxHeight: "100vh" }}
    >
      <Container
        className="pb-lg-5"
        style={{ height: large && "100%", maxHeight: "100vh" }}
      >
        <Modal.Header
          closeButton
          className="d-flex justify-content-between w-100"
        >
          <Modal.Title className="inter-700">
            {products?.length} Branded products available
          </Modal.Title>
          {/* <div className="body-title inter-700 mb-2"> 50,000 brand products available </div> */}
          {/* <Button className="d-flex text-white" onClick={() => setShow(false)}> Continue </Button> */}
        </Modal.Header>
        <Modal.Body className="overflow-auto pb-5" style={{ height: "100%" }}>
          {large && <FilterFields />}
          <Row>
            <Col lg={3} className="p-1 p-sm-2">
              {/* <div className="border rounded p-2"></div> */}
              <div
                className="border p-2 mb-3 mb-lg-0 "
                style={{ borderRadius: 12 }}
              >
                <div
                  className={`d-flex flex-column justify-content-between mb-2 ${
                    large && "h-100"
                  }`}
                >
                  <div
                    className="d-flex flex-lg-column flex-grow h-100"
                    style={{ minHeight: large && 300, overflowX: "scroll" }}
                  >
                    {selectedRoomtypes[roomtypeIndex]?.rooms?.map(
                      (item, index) => (
                        <div
                          key={index}
                          className="mb-lg-3 me-3 me-lg-0 flex-grow"
                        >
                          <Card
                            className={`cursor-pointer p-0 overflow-hidden w-100 shadow-sm rounded ${
                              activeRoomIndex === index
                                ? "border-primary shadow-sm"
                                : ""
                            }`}
                            style={{
                              minWidth: 180,
                              opacity: activeRoomIndex === index ? 1 : 0.5,
                              borderWidth: 1.4,
                            }}
                            onClick={() => {
                              // console.log(selectedRoomtypes[roomtypeIndex]);
                              setActiveRoomIndex(index);
                            }}
                          >
                            <Card.Img
                              className="img-fluid"
                              variant="top"
                              // src={selectedRoomtypes[roomtypeIndex]?.image}
                              src={
                                selectedRoomtypes[roomtypeIndex]?.rooms[index]
                                  ?.file
                                  ? URL.createObjectURL(
                                      selectedRoomtypes[roomtypeIndex]?.rooms[
                                        index
                                      ]?.file
                                    )
                                  : null
                              }
                              style={{
                                maxHeight: 120,
                                minHeight: 120,
                                objectFit: "cover",
                              }}
                            />
                            <Card.Body className="p-2 px-xl-3">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="inter-500">
                                  {selectedRoomtypes[roomtypeIndex]?.title}
                                </div>
                                <div className="inter-500">
                                  ({index + 1}/
                                  {
                                    selectedRoomtypes[roomtypeIndex]?.rooms
                                      .length
                                  }
                                  )
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {large && (
                  <div className="my-2 text-center w-100">
                    <Button
                      className="text-white"
                      style={{ width: 180 }}
                      onClick={() => setShow(false)}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={9}>
              {!large && <FilterFields />}
              <Row>
                {products.map((product, index) => {
                  // const isChecked = checkedProducts?.some(
                  //   (p) => p === product.id
                  // );
                  return (
                    <Col
                      key={index}
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={3}
                      className="mb-3 d-flex flex-column p-1 p-sm-2"
                    >
                      <div className="position-relative d-flex flex-column flex-grow">
                        <ProductListCard
                          {...product}
                          image={
                            product.media[0]?.file ??
                            product.product_type_data?.image
                          }
                          product_type={product.product_type_data?.title}
                          showDetails={false}
                          className="flex-grow"
                          onClick={() => handleCheckboxChange(product.id)}
                        />
                        {/* <div className="position-absolute" style={{ top: 0, right: 0 }}>
                        <div class="checkbox-container">
                          <input
                            type="checkbox"
                            size={40}
                            checked={checkedProducts?.some((p) => p === product.id)}
                            // checked={selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]?
                            // .products.some((pid) => pid === product.id)}
                            onChange={() => handleCheckboxChange(product.id)}
                            id="checkbox"
                            className="position-absolute"
                            style={{ top: 0, right: 0 }}
                          />
                        </div>
                      </div> */}
                        {/* <input
                        type="checkbox"
                        // size={40}
                        checked={checkedProducts?.some((p) => p === product.id)}
                        // checked={selectedRoomtypes[roomtypeIndex]?.rooms[activeRoomIndex]?
                        // .products.some((pid) => pid === product.id)}
                        // onChange={() => handleCheckboxChange(product.id)}
                        id="checkbox"
                        className="position-absolute rounded-circle"
                        style={{top: -8, right: -8, width: 20, height: 20, borderRadius: 10,}} /> */}
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                          style={{
                            top: 0,
                            right: 0,
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                          }}
                        >
                          <div className="d-flex justify-content-center align-items-center rounded-circle">
                            {checkedProducts?.some((p) => p === product.id) ? (
                              // <FaCircleCheck size={24} color="#06bf00" />
                              <IoIosRadioButtonOn
                                size={32}
                                className="text-primary"
                              />
                            ) : (
                              // <FaRegCheckCircle size={24} style={{ opacity: 0.5 }} />
                              <FaCircle
                                size={28}
                                className="bg-gray rounded-circle"
                                style={{ opacity: 0.5 }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <div className="d-flex justify-content-center w-100">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Container>
    </Modal>
  );
};

export default AddProductsModal;
